.popup-bg{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    background: #00000020;
    padding: 0 3.1rem;
}
.popup-box{
    background: white;
    width: 100%;
    margin-top: 29%;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 3.1rem 2.5rem;
    cursor: pointer;
}
.popup-content{
    width: 100%;
    font-family: 'pretendard-Regular';
    font-size: 1rem;
}
.popup-btn{
    width: 100%;
    padding-right: 1rem;
    text-align: right;
    font-size: 1.3rem;
    font-family: 'Pretendard-SemiBold';
}