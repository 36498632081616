.finishSign{
    width: 100%;
    height: 100dvh;
    background-color: white;
    padding: 0 1.8rem;
    padding-bottom: 3.7rem;
    display: flex;
    flex-direction: column;
    gap: 7.8rem;
}
.finishsign-title{
    color: #000000;
    line-height: 3.2rem;
    font-family: 'Pretendard-Bold';
    font-size: 1.7rem;
    margin-top: 8.5rem;
}
.finishsign-subtxt{
    color: #A7A7A7;
    font-family: 'Pretendard-Medium';
    font-size: 1.2rem;
    text-align: center;
    width: 100%;
}
.finishsign-img{
    width: 18rem;
    margin: 0 auto;
}