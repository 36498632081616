.signpage{
    width: 100vw;
    height: 100dvh;
    max-width: 500px;
    padding: 6.9rem 2.1rem;
    display: flex;
    flex-direction: column;
}
.signpage-title{
    font-family: "Pretendard-Regular";
    font-size: 1.73rem;
    margin-bottom: 5.7rem;
}
.signpage-check-area{
    display: flex;
    padding: 0.92rem 0;
    align-items: center;
    font-family: 'Pretendard-Regular';
    font-size: 1.3rem;
}
.sign-checkbox {
    display: flex;
    margin-right: 10px;
    position: relative;
}


.sign-checkbox input[type="checkbox"] {
    width: 20px;
    height: 20px;
}
.notice-open-btn{
    width: 0.92rem;
    display: flex;
    margin-left: auto;
    height: 2rem;
}
.notice-open-btn img{
    display: flex;
    width: 100%;
    object-fit: contain;
}
.notice-box{
    display: none;
}
.show-notice{
    display: block !important;
    background-color: #F5F5F5;
    padding: 0.9rem;
    border: 1px solid #D9D9D9;
    height: 50%;
    overflow: scroll;
}

.signpage-btn-area{
    width: 100%;
    position: relative;
    flex: 1;
}
.signpage-btn{
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 1.4rem 0 ;
    background-color: #D9D9D9;
    color: white;
    font-family: 'Pretendard-Bold';
    font-size: 1.3rem;
    border-radius: 10px;
}
.signpage .btn-active{
    background-color: #FF9205;
}
.signpage-btn-area .active{
    background-color: #FF9205;
}

/* nickname-step1 */
.step1-input-flex{
    display: flex;
    align-items: last baseline;
}
.step1-date-input{
    border: none;
    width: 90%;
}
.step1-input-cnt{
    font-family: 'Pretendard-Medium';
    font-size: 1.35rem;
    color: #B4BAC5;
}
/* nickname-step2 */
.nickname-step2-input{
    margin-bottom: 3.2rem;
}
.input-label{
    font-size: 1.1rem;
    font-family: 'Pretendard-Regular';
    color: #B4BAC5;
    margin-bottom: 0.55rem;
}
.input-box{
    display: flex;
    align-items: center;
}
.step2-date-input{
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0.92rem;
    text-align: left;
    border-bottom: 1px solid #24262D !important;
    border: none;
}