@keyframes shown {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.intropage {
  position: relative;
  max-width: 500px;
  width: 100vw;
  height: 100dvh;
  text-align: center;
  padding-bottom: 5rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.intropage-title {
  padding-top: 6.9rem;
  font-size: 2.3rem;
}
.intropage-sns {
  margin: 0 auto;
  padding: 1.3rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
  border: 0.5px solid #8c96a4;
  border-radius: 14px;
  background-color: white;
}
.intropage-kakao {
  margin-bottom: 1rem;
}
.intropage-apple {
  margin-bottom: 2.75rem;
}
.intropage-kakao:active,
.intropage-kakao:hover {
  background-color: #fae84c;
}
.intropage-apple:active,
.intropage-apple:hover {
  background-color: #ffffff;
}
.intropage-kakao-img {
  width: 11rem;
}
.profile-img {
  position: relative;
  top: 15%;
  height: 50%;
  margin: 0 auto;
}
.intropage .profile-img img {
  width: 100%;
  object-fit: contain;
}
.intropage .into-bottom {
  position: relative;
  flex: 1;
  width: 100%;
}
.intropage .intro-bottom-abs {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.intropage .nextpage {
  padding: 0 1.8rem;
  position: relative;
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
}
.intropage .firstpage {
  width: 100%;
  height: 100dvh;
  background-color: white;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.firstpage .firstpage-logo {
  width: 22rem;
  animation: shown 3.1s forwards;
}

.landing-tuto {
  color: #8f94a2;
  font-family: "Pretendard-Regular";
  font-size: 1.3rem;
  text-decoration: underline;
}
