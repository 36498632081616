.vector-img {
  width: 0.8rem;
  height: 1.1rem;
  display: flex;
}
.vector-img img {
  height: 100%;
  object-fit: contain;
}
/* setting 페이지 스위치 커스텀 */
.switch {
  position: relative;
  display: flex;
  width: 2.3rem;
  height: 1.3rem;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d7dae0;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 0.9rem;
  width: 0.9rem;
  left: 3px;
  bottom: 0.2rem;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ff9205;
}

input:checked + .slider:before {
  transform: translateX(0.9rem);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/* 페이지 */
.setting-page {
  padding: 1.57rem 0 6rem 0;
}
.setting-title {
  font-size: 1.55rem;
  padding: 0 1.55rem;
  font-family: "Pretendard-Regular";
}
.setting-service,
.setting-qa {
  border-bottom: solid #f7f8f8 4px;
  padding: 0 1.55rem;
  padding-top: 1.8rem;
}
.setting-agency {
  padding: 0 1.55rem 0.55rem 1.55rem;
  padding-top: 1.8rem;
}
.setting-subtitle {
  font-family: "Pretendard-Regular";
  font-size: 1.18rem;
  color: #667080;
}
.setting-service-list {
  padding: 0.92rem 1.3rem 1.23rem 1.3rem;
  display: flex;
  flex-direction: column;
  gap: 1.86rem;
  justify-content: space-between;
}
.setting-service-li {
  font-size: 1.3rem;
  font-family: "Pretendard-Regular";
  color: #3e434e;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.setting-alarm {
  border-bottom: solid #f7f8f8 4px;
  padding: 0.8rem 1.6rem;
}
.setting-alarm-box-1 {
  padding: 1rem 0.7rem 0.62rem 1.28rem;
  justify-content: space-between;
  align-items: center;
  font-family: "Pretendard-Regular";
  font-size: 1.3rem;
}
.setting-alarm-box-2 {
  padding: 0.5rem 0.7rem 0.5rem 2.2rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
.setting-alarm-li-title {
  font-family: "Pretendard-Regular";
  font-size: 1.15rem;
  color: #3e434e;
}
.setting-alarm-li-subtitle {
  font-family: "Pretendard-Regular";
  font-size: 1.05rem;
  color: #8c96a4;
}
.setting-alarm-li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.logout-btn {
  padding: 1.33rem 0;
  text-align: center;
  font-family: "Pretendard-Regular";
  font-size: 1.3rem;
  color: #3e434e;
  border-top: solid #f7f8f8 4px;
  border-bottom: solid #f7f8f8 4px;
}
.exit {
  color: #b4bac5;
  text-decoration: underline;
  font-size: 1.2rem;
  font-family: "Pretendard-Regular";
  padding: 0.92rem 2.3rem;
}

/* 닉네임 page */
.back-img {
  width: 0.91rem;
  height: 1.3rem;
  margin: 1.3rem 2.4rem;
}
.nickname-area {
  padding: 3.42rem 2.7rem;
}
.nickname-title {
  font-family: "Pretendard-Regular";
  font-size: 1.2rem;
  color: #667080;
}
.nickname-input {
  padding-top: 1.32rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nickname-input input {
  border: none;
  width: 90%;
  border-bottom: 0.4px solid #000000;
  padding: 0.55rem 0.8rem;
  color: #24262d;
  font-size: 1.55rem;
  font-family: "Pretendard-Bold";
}
.edit-img {
  width: 1.8rem;
}
.nickname-btn-area {
  position: absolute;
  width: 100%;
  bottom: 3rem;
  padding: 0 1.58rem;
  max-width: 500px;
}
.nickname-btn {
  border-radius: 10px;
  color: white;
  background-color: #ff9205;
  text-align: center;
  font-family: "Pretendard-Bold";
  font-size: 1.3rem;
  padding: 1.58rem;
}
.nickname-btn.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.warning {
  color: red;
  margin-top: 10px;
}

/* 탈퇴 페이지 */
.signout-page {
  z-index: 99;
  position: fixed;
  width: 100%;
  height: 100dvh;
  max-width: 500px;
  touch-action: none;
  background-color: #00000043;
}
.signout-pos {
  height: 100%;
  padding: 2.2rem;
  position: relative;
  width: 100vw;
  max-width: 500px;
}
.signout-box {
  margin-top: 50%;
  padding: 3rem 4.5rem;
  background-color: white;
  border-radius: 20px;
}
.signout-box .signout-btn {
  margin-top: 3rem;
  background-color: #ef4207;
  color: white;
  padding: 1.38rem 0;
  text-align: center;
  font-family: "Pretendard-Bold";
  font-size: 1.3rem;
  border-radius: 10px;
}
.signout-box .signout-exit {
  width: 100%;
  text-align: center;
  margin-top: 1rem;
  color: #434343;
  font-family: "Pretendard-Regular";
  font-size: 1.3rem;
}

.signout-none {
  display: none !important;
}
.signout-fixed {
  position: fixed;
  width: 100vw;
  max-width: 500px;
}

.btn-notice-div {
  bottom: 4rem;
  padding: 1rem 0;
  position: absolute;
  width: 100%;
  background: white;
}
.notice-img-div {
  width: 70%;
  margin: 0 auto 1rem auto;
}
