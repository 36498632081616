/* 애니메이션 모음 */
@keyframes grow {
    from {
      width: 0%;
    }
    to {
      width: 100%; }
  }
@keyframes down {
from {
    transform: scale(0.5) translateY(-120%);
}
to {
    transform: scale(1) translateY(0);
}
}

@keyframes right {
    from {
        transform: scale(0.5) translateX(-500%);
    }
    to {
        transform: scale(1) translateY(0);
    }
}

@-webkit-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {-webkit-transform: scale(1) translateY(0);}
    40% {-webkit-transform: scale(1.5) translateY(-30px) }
    60% {-webkit-transform: scale(1.2) translateY(-20px);}
} 
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {transform: scale(1) translateY(0);}
    40% {transform: scale(1.5) translateY(-30px);}
    60% {transform: scale(1.2)  translateY(-20px);}
} 

.mainpage {
    /* padding: 1rem 1.9rem; */
    padding-bottom:8rem !important;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.mainpage-padding{
    padding-bottom:15rem !important;
}
.mainpage-real{
    padding: 1rem 1.9rem;
}
.mainpage-level-landing{
    background-color: #F6F7F8;
    color:black;
    display: flex;
    border-radius: 22px;
    gap: 0.7rem;
    padding: 0.7rem 1.3rem;
    align-items: center;
}
.mainpage-level-landing .mainpage-level-num{
    background-color: #FFE9D4;
    color: #FF7A2F;
    border-radius: 15px;
    padding: 0.4rem 0.7rem;
    font-family: 'Pretendard-Medium';
    font-size: 1rem;
    gap: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mainpage-level-landing .mainpage-level-txt{
    font-family: 'Pretendard-Medium';
    font-size: 1rem;
}
.mainpage-title {
    margin-top: 2.42rem;
    padding: 0 0.7rem;
    font-family: 'Pretendard-Bold';
    font-size: 2rem;
}
.mission-tag1,.mission-tag2 {
    font-family: 'Pretendard-Bold';
    font-size: 2rem;
    color: #FF9205;
    margin-right: 0.7rem;
}
.mainpage-img{
    width: 9rem;
    margin: 1.5rem 0.5rem 1.5rem auto;
    animation: bounce 1s 2;
}
.mainpage-cam-area{
    text-align: center;
}
#container {
    margin: 0 auto;
    width: 500px;
    height: 375px;
    border: 10px #333 solid;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#videoElement {
    width: 500px;
    height: 375px;
    background-color: #666;
}

button {
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    background-color: #FF6347;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #ff4500;
}

.captured-photo {
    width: 100%;
    height: auto;
    max-width: 500px;
    margin: 20px 0;
    border: 2px solid #ddd;
    border-radius: 8px;
}
.camera-img-area{
    margin: 0 auto;
    text-align: center;
}

/* 미션 박스 style */
.mainpage-mission-zone{
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
}
.mainpage-mission-box{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 1.3rem;
    background-color: #FF7A2F;
    border-radius: 8px;
}

.special-background {
    background: linear-gradient(90deg, #FC7870 0%, #F1AA7C 20%, #E7D487 45%, #8FE5CA 70%, #9494E3 100%) !important;
  }
  .mission-finished{
    background:  #F6F7F8 !important;
    color: #A5AAB5 !important;
    
 }
.mission-tag-flex{
    display: flex;
    flex-direction: row;
    gap: 0.7rem;
}
.mission-tag{
    padding: 0.4rem 0.7rem;
    background-color: white;
    margin-bottom: 0.5rem;
    font-family: 'Pretendard-Bold';
    font-size: 1.1rem;
    color: #FF7A2F;
    border-radius: 5px;
}
.mission-subtitle{
    font-family: 'Pretendard-Medium';
    font-size: 1.2rem;
    color: white;
    margin-bottom: 0.3rem;
}
.mission-title{
    font-family: 'Pretendard-Bold';
    font-size: 1.38rem;
    color: white;
}
.mission-finished .mission-title{
    color: #A5AAB5 !important;
}
.mainpage-mission-check{
    border-color: #FFFFFF;
    accent-color: #DEE0E4;
    padding: 12.5px 11px;
    gap: 5px;
    width: 1.2rem;
    height: 1.2rem;
    background: #FFFFFF;
    border-radius: 4px;
}
.mission-checkbox-checked{
    background-color: #8F94A2;
    accent-color: #8F94A2;
}
.mission-finished .mission-tag-checked{
    background-color: #DEE0E4 !important;
    color: #A5AAB5 !important;
}

.mission-now-clicked{
    z-index: 9 !important;
}

.mission-img-box{
    width: 3.9rem;
    height: 3.9rem;
    border-radius: 10px;
}
.mission-img-box img{
    object-fit: cover;
    border-radius: 10px;
    height: 100%;
}
/* 메인- 카메라 */
.camera-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .camera-video {
    width: 100%;
    height: 100dvh;
    object-fit: none;
  }
  
  .capture-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 18px;
    color: white;
    background-color: red;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
/* 메인 - 레벨 페이지 */
.main-levelpage{
    display: flex;
    flex-direction: column;
    height: 100dvh;
}
.backbtn{
    padding: 1.8rem 1.3rem 1.4rem 1.3rem;
    background-color: rgba(255, 255, 255, 0.881);
    width: 100%;
    position: fixed;
    top: 0;
}
.main-levelpage-top{
    padding: 0 1.3rem 2rem 1.3rem;
}
.main-levelpage-top .level-top-title{
    margin-top: 5rem;
}
.main-levelpage-top .level-top-title-1{
    font-size: 1.9rem;
    font-family: 'Pretendard-Bold';
}
.main-levelpage-top .level-top-title-2{
    font-size: 1.41rem;
    font-family: 'Pretendard-Bold';
}
.main-levelpage-top .level-top-img{
    width: 10.5rem; 
    margin: 1.5rem auto;
    animation: down 1s 1;
}
.main-levelpage-level{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 9px;
}
.main-levelpage-level .level-value{
    font-size: 1rem;
    font-family: 'Pretendard-Medium';
    color: #BBBBBB;
    margin-left: auto;
}
.main-levelpage-level .outer{
    width: 100%;
    height: 9px;
    background-color: #DEE0E4;
    border-radius: 8px;
}

.main-levelpage-level .inner{
    height: 9px;
    background-color: #FF7A2F;
    border-radius: 8px;
    width: 0;
    transition: width 2.5s ease-in-out; /* Smooth transition */
}
.main-levelpage-level .level-change{
    display: flex;
    justify-content: space-between;
    padding: 0 0.7rem;
}
.main-levelpage-level .level-tag{
    display: flex;
    border : solid #FFE9D4 0.75px;
    text-align: center;
    color:  #FF7A2F;
    font-family: 'Pretendard-Medium';
    font-size: 1.1rem;
    border-radius: 15px;
    padding: 0.38rem 0.7rem;
}
.main-level-bottom{
    flex: 1;
    padding: 2.3rem 2rem 8rem 2rem;
    background-color: #F6F7F8;
}

/* roundslide 영역 */
/* swiper*/
.swiper-wrapper{
    display: flex;
    z-index: 10;
    position: absolute;
    width: 100%;
    justify-content: center;
    bottom: 27%;
    gap: 1rem;

    /* overflow: hidden; */
}
.roundslide-area{
    max-width: 500px;
    width: 100%;
    height: 100dvh;
    position: fixed;
}
.roundslide-area .pos-rel{
    position: relative;
    width: 100%;
    height: 100dvh;
    background: #5a5a5ab3;
    overflow: hidden;
}
 
  .carousel-container {
    /* display: flex; */
    align-items: center;
    justify-content: center;
    /* padding: 20px; */
  }
  
  .round-slide-item {
    display: flex;
    align-items: center;
    background-color: #DEE0E4;
    width: 5.3rem !important;
    text-align: center;
    height: 5.3rem;
    padding: 1.2rem;
    border-radius: 999px;
    justify-content: center;
    margin-top: 5.9rem;
  }
  .swiper-slide-next, .swiper-slide-prev{
    margin-top: 3.5rem !important;
  }
  .carousel-item {
    /* margin: 0 5px;
    transition: transform 0.3s;
    flex: 0 0 20%; */
  }
  /* .round-0-out,.round-4-out{
    margin-top: 5.9rem;
  } */
  /* .round-1-out,.round-3-out{
    margin-top: 3.5rem;
  } */

  .roundslide-img {
   width: 3.5rem;
    cursor: pointer;
    /* border-radius: 50%; */
  }
  
  .center {
    /* transform: scale(1.5); */
    width: auto !important;
    height: auto !important;
    background-color: white !important;
    padding: 0.8rem !important;
    opacity: none;
    margin-top: 0 !important;
  }
  .center .roundslide-img {
    width: 4.5rem;
  }

  

  .roundslide-submit-btn-area{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100dvh;
    text-align: center;
  }
.roundslide-submit-btn{
    font-family: 'Pretendard-Bold';
    font-size: 1.3rem;
    line-height: 1.7rem;
    position: absolute;
    bottom: 13.8%;
    width: 5.9rem;
    height: 5.9rem;
    border-radius: 999px;
    background-color: #8FBCFF;
    text-align: center;
}
.roundslide-submit-btn:active{
    opacity: 50%;
}
.goOut{
    height: 100dvh;
    position: absolute;
    width: 100vw;
    color: white;
    font-size: 1.5rem;
    text-align: right;
    padding: 2rem;
}