@font-face {
font-family: 'Pretendard-400';
src: url('https://cdn.banggooso.com/assets/fonts/PretendardVariable.woff2') format('woff2');
font-weight: 400;
}

@font-face {
font-family: 'Pretendard-500';
src: url('https://cdn.banggooso.com/assets/fonts/PretendardVariable.woff2') format('woff2');
font-weight: 500;
}

@font-face {
font-family: 'Pretendard-600';
src: url('https://cdn.banggooso.com/assets/fonts/PretendardVariable.woff2') format('woff2');
font-weight: 600;
}

@font-face {
font-family: 'Pretendard-700';
src: url('https://cdn.banggooso.com/assets/fonts/PretendardVariable.woff2') format('woff2');
font-weight: 700;
}

@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css');
@font-face {
font-family: 'Pretendard-Regular';
src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
font-style: normal;
}
@font-face {
font-family: 'Pretendard-Bold';
src: url('https://cdn.banggooso.com/sr/assets/fonts/Pretendard-Bold.otf') format('opentype'),
  url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Thin.woff2https://cdn.banggooso.com/sr/assets/fonts/Pretendard-Bold.woff2')
    format('woff2'),
  url('https://cdn.banggooso.com/sr/assets/fonts/Pretendard-Bold.woff') format('woff');
font-style: normal;
}

@font-face {
font-family: 'Pretendard-Medium';
src: url('https://cdn.banggooso.com/sr/assets/fonts/Pretendard-Medium.otf') format('opentype'),
  url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Thin.woff2https://cdn.banggooso.com/sr/assets/fonts/Pretendard-Medium.woff2')
    format('woff2'),
  url('https://cdn.banggooso.com/sr/assets/fonts/Pretendard-Medium.woff') format('woff');
font-style: normal;
}

@font-face {
font-family: 'Pretendard-Regular';
src: url('https://cdn.banggooso.com/sr/assets/fonts/Pretendard-Regular.otf') format('opentype'),
  url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Thin.woff2https://cdn.banggooso.com/sr/assets/fonts/Pretendard-Regular.woff2')
    format('woff2'),
  url('https://cdn.banggooso.com/sr/assets/fonts/Pretendard-Regular') format('woff');
font-style: normal;
}

@font-face {
font-family: 'Pretendard-SemiBold';
src: url('https://cdn.banggooso.com/sr/assets/fonts/Pretendard-SemiBold.otf') format('opentype'),
  url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Thin.woff2https://cdn.banggooso.com/sr/assets/fonts/Pretendard-SemiBold.woff2')
    format('woff2'),
  url('https://cdn.banggooso.com/sr/assets/fonts/Pretendard-SemiBold') format('woff');
font-style: normal;
}

* {
  box-sizing: border-box;
  font-family: 'Pretendard';
  list-style: none;
  margin: 0;
  outline: none;
  padding: 0;
}
/* 공통 */
.blue-txt{
  color: #2B6BCA;
}
.orange-txt{
  color: #FF7A2F;
}
.bold-txt{
  font-family: 'Pretendard-Bold';
}
.img-width{
  width: 100%;
}
.display-flex{
  display: flex;
}
.flex-row{
  display: flex;
  flex-direction: row;
}
.flex-col{
  display: flex;
  flex-direction: column;
}
a {
  text-decoration: none;
}

body, html {
  background: white;
  height: 100%;
}

.clear-flex-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 0 1rem 0;
}
.dash-page{
  cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
}
.dash-page .dash-top{
 
  border-bottom: 8px solid #EDEEF1;
}
.dash-page .dash-title{
  font-family: 'Pretendard-Bold';
  font-size: 1.8rem;
  width: 100%;
  padding: 2rem 1.62rem 1.125rem 1.62rem;
}

.dash-page .clear-count-box{
  padding: 0rem 2.81rem;
  width: 100%;
  text-align: center;
}
.dash-page .clear-count-box-1{
  border-right:  1px solid #DCDCDC;

}
.dash-page .clear-title{
  font-family: 'Pretendard-Medium';
  color :#586171;
  text-align: center;
  font-size: 1.12rem;
  margin-bottom: 0.62rem;
}
.dash-page .clear-cnt{
  font-family: 'Pretendard-Bold';
  font-size: 1.75rem;
}




/* 캘린더 영역 */
.dash-calendar{
  padding: 1.9rem 1.9rem 2.1rem 1.9rem;
  border-bottom: 8px solid #EDEEF1;
}
.mini-title{
  font-family: 'Pretendard-SemiBold';
  font-size: 1.2rem;
  color:#6D6D6D;
}
.dash-calendar .today-month{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap : 1.3rem;
}
.dash-calendar .month-txt{
  font-family: 'Pretendard-Regular';
  font-size: 1.5rem;
}
.dash-calendar .cal-mission{
  margin-top: 2.1rem;
  margin-bottom: 1.95rem;
  font-family: 'Pretendard-Bold';
  font-size: 1.7rem;
}

/* 캘린더 */
.calendar {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  cursor: pointer;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.month-display {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.arrow {
  cursor: pointer;
}

.day-names {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 1.03rem;
  font-size: 1.15rem;
}


.week {
  display: flex;
  font-size: 1.25em;
  font-family: 'Pretendard-Regular';
  justify-content: space-between;
  width: 100%;
  gap: 2rem;
  margin-bottom: 1.03rem;
}

.day {
  color: #484B52;
  width: 2.2rem;
  height: 2.2rem;
  display: flex;
  font-size: 1.2rem;
  align-items: center;
  justify-content: center;
  font-family: 'Pretendard-400';
}



.selected {
  background-color: lightblue;
}

.different-month {
  color: lightgray !important;
  background: none !important;
}

.container {
  margin-top: 2rem;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}




.week .active1 {
  background: #E2E8F0;
  border-radius: 50%;
}
.week .active2 {
  background: #8FBCFF;
  border-radius: 50%;
  color: white;
}
.week .active3 { 
   background: #FF7A2F;
  border-radius: 50%;
  color: white;
}


.weeks .week-date:not(.last-month):hover {
  cursor: pointer;
  font-weight: 600;
}



.dash-page .whats-color{
  margin-top: 1.03rem;
  border-top: 1px solid #DCDCDC;
  padding-top: 1.9rem;
  display: flex;
  justify-content: space-between;
}
.dash-page .whats-color-img{
  width: 8rem;
  margin-right: 0.5rem;
}
.dash-bottom{
  padding: 1.9rem;
}
.dash-bottom .mini-title{
  margin-bottom: 1.9rem;
}
.dash-bottom .flex-row{
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.dash-bottom .bottom-txt{
  color: #586171;
  font-size: 1.35rem;
  font-family: 'Pretendard-Medium';
}
.dash-bottom .bottom-result{
  font-size: 1.5rem;
  font-family: 'Pretendard-Bold';
}

/* 상세페이지 : 주변달성률 */
.detail-page{
  width: 100%;
  max-width: 500px;
  height: 100dvh;
  position: relative;
  overflow: scroll;
}
.detail-page .detail-top{
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 500px;
  padding: 1.7rem 1.3rem 0.9rem 1.3rem;
  text-align: center;
  background-color: white;
  border-bottom: 8px #EDEEF1 solid;
}
.detail-top .detail-top-title {
  display: flex;
  gap: 31%;
  align-items: center;
}
.detail-top .detail-top-back {
  width: 1.2rem;
}
.detail-top .top-title{
  font-family: 'Pretendard-Bold';
  font-size: 1.8rem;

}
.detail-top .this-week{
  margin-top: 3.2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.detail-top .this-week-day{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.67rem;
  gap: 1.3rem;
}
.detail-top .today{
  background-color: #EBECEE;
  border-radius: 20px !important;
}
.detail-top .day-txt{
  color : #616161;
  font-family: 'Pretendard-Regular';
  font-size: 1.1rem;
}
.detail-top .day-date{
  color : #484B52;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Pretendard-Regular';
  font-size: 1.3rem;
  width: 1.9rem;
  height: 1.9rem;
}
.detail-top .daily-count-1 .day-date{
  background-color: #E2E8F0;
  border-radius: 999px;
  padding: 1.2rem;
}
.detail-top .daily-count-2 .day-date{
  color: white !important;
  background-color: #8FBCFF;
  border-radius: 999px;
  padding: 1.2rem;
}
.detail-top .daily-count-3 .day-date{
  color: white !important;
  background-color: #FF7A2F;
  border-radius: 999px;
  padding: 1.2rem;
}
.detail-page .no-mission-area{
  width: 100%;
  justify-content: center;
  display: flex;
  margin-top: 50%;
}
.detail-page .no-mission-img{
  width: 11rem;
}
.detail-page .detail-bottom{
  padding: 17rem 1.3rem 3rem 1.3rem;
}
.detail-page .detail-mission{
  padding: 1.1rem 1.3rem;
  border-bottom: solid 1px #DCDCDC;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.detail-mission .mission-type{
  color: #FF7A2F;
  font-family: 'Pretendard-medium';
  font-size: 0.9rem;
}
.detail-mission .mission-subtxt{
  color: #333333;
  font-family: 'Pretendard-Regular';
  font-size: 1.2rem;
  margin-top: 0.3rem;
}
.detail-mission .mission-title{
  color: black;
  font-family: 'Pretendard-Bold';
  font-size: 1.5rem;
  margin-top: 0.5rem;
  flex : 1;
}
.mission-txt-flex{
  display: flex;
  flex-direction: column;
}
.detail-mission .mission-image{
  margin-top: 1.3rem;
  width: 100%;
  width: 4.1rem;
}
.detail-mission .no-mission-img{
  display: none !important;
}
.detail-mission .mission-image img{
  object-fit: cover;
  height: 100%;
  border-radius: 10px;
}
