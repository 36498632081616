

.typepage-hidden{
  overflow: hidden;
}

.typepage-main{
  /* position: fixed; */
    width: 100%;
    max-width: 500px;
    height: 100dvh;
    padding: 3rem 1.75rem;
    display: flex;
    flex-direction: column;
}
.typepage-title{
    padding: 0 0.1rem;
}
.type-select-area{
    padding: 0 0.8rem;
    margin-top: 2.8rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1.2rem;
    width: 100%;
}
.type-box{
  width: 47.5%;
  padding: 1.3rem 1rem;
  background: #F6F7F8;
  box-shadow: 0px 2px 6.2px rgba(101, 118, 133, 0.25);
  border-radius: 10px;
}
.type-box-1-active, .type-box-2-active,.type-box-3-active,.type-box-4-active{
    background-color: #FFE9D4;
    border: #FF7A2F solid 2px;
}
.typepage-landing-test{
  font-size: 1rem;
  margin-bottom: 1rem;
  text-align: center;
}
.typepage-btn-area{
  width: 100%;
  padding-bottom: 2.6rem;
  position: relative;
  flex:1;
}
.typepage-btn-area-abs{
  position: absolute;
  bottom: 0;
  width: 100%;
}
.typepage-btn{
  background: #EDEEF1;
  border-radius: 10px;
  color: #C5C5C5;
  padding: 1.5rem 1.8rem;
  text-align: center;
  font-size: 1.3rem;
  font-family: 'Pretendard-Bold';
}
.typepage-btn-active{
  background-color: #FF7A2F;
  color: white;
}
.typepage-landing-test{
  color: #717484;
  font-family: 'Pretendard-Regular';
  font-size: 1.2rem;
  text-decoration: underline;
}

/* about페이지 부분 */
.aboutpage{
    max-width: 500px;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100dvh;
    background-color: #0000004c;
    z-index: 5;
}
.aboutpage-main{
    position: absolute;
    bottom: 0;
    overflow: scroll;
    background-color: white !important;
    border-radius: 15px 15px 0 0;
    text-align: center;
    background-color: #C5C5C5;
    width: 100%;
    height: 80dvh;
}
.aboutpage-detail{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.55rem;
  max-width: 500px;
  padding-bottom: 10rem;
}
.aboutpage .aboutpage-close{
  position: absolute;
  top: 3rem;
  right: 2.2rem;
  width: 1.3rem;
}
.aboutpage .about-title{
  padding: 3rem 1.5rem 1rem 1.5rem;
}
.aboutpage .about-txt{
  margin-top: 3rem;
}
.about-icon{
    width: 9.1rem;
}
.aboutpage-btn-area{
    width: 100%;
    background-color: white;
    position: fixed;
    max-width: 500px;
    bottom: 0;
    padding: 2.3rem 1.55rem 2.1rem 1.55rem;
}
.aboutpage-btn{
    border-radius: 5px;
    background-color: #798097;
    color: white;
    color: white;
    font-size: 1.3rem;
    font-family: 'Pretendard-Bold' !important;
    padding: 1.6rem;
}
.aboutpage-btn:active{
  opacity: 70%;
}

/* setting 설정 페이지 */
.setting-edit-type-area .typepage-main{
  margin-top: -5rem;
  padding: 5rem 1.75rem 2rem 1.75rem !important;
}
.setting-edit-type-area .typepage-title{
  width: 23rem;
  margin-left: 0.8rem;
  margin-top: 1rem;
}
.setting-edit-type-area .type-select-area {
  margin-top: 2rem;
} 
.setting-typepage-header {
  display: flex;
  width: 100%;
  background-color: white;
  justify-content: space-between;
  padding: 1.58rem 1.25rem;
}
.setting-typepage-header .header-back{
  width: 1rem;
}
.setting-typepage-header .header-title{
  font-size: 1.5rem;
  font-family: 'Pretendard-Bold';
  margin-right: 30%;
}

@media (min-height: 850px) {
  .setting-edit-type-area .typepage-main{
    padding: 5rem 1.75rem 8rem 1.75rem !important ;
  }
  .setting-edit-type-area .aboutpage-detail{
    padding-bottom: 15rem !important;
  }
  .setting-edit-type-area .aboutpage-btn-area{
    padding: 2.3rem 1.55rem 7rem 1.55rem !important;
  }
}